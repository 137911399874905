<template>
  <b-dropdown
    lazy
    :variant="isThemeHeader === 'true' ? 'dark' : 'light'"
    size="sm"
    toggle-class="text-decoration-none btn-transparent mr-2"
    menu-class="w-300px"
    :title="getClosedTypeTitle(colDate)"
    :disabled="!(canOpenDay && canCloseDay)"
    :no-caret="!(canOpenDay && canCloseDay)"
  >
    <template #button-content>
      <span name="day-indicator" :class="getBroadcastingDisallowedEjectionTypeClasses(colDate)"></span>
      <span name="day-indicator" :class="getClosedTypeClasses(colDate)"></span>
      <b-icon v-if="dayBroadcastingSettings.is_closed === 0" :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'" icon="unlock"></b-icon>
      <b-icon v-else class="danger-color" icon="lock"></b-icon>
    </template>
    <b-dropdown-text>{{ $t('table.closedFor') }}:</b-dropdown-text>
    <b-dropdown-item href="#" @click="openOrCloseDay(dayBroadcastingSettings, 3)">
      <span class="text-danger" :style="{ visibility: dayBroadcastingSettings.is_closed === 3 ? 'visible' : 'hidden' }" aria-hidden="true"> &check; </span>
      {{ $t('table.allGenitive') }}
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item href="#" @click="openOrCloseDay(dayBroadcastingSettings, 1)">
      <span class="text-danger" :style="{ visibility: (dayBroadcastingSettings.is_closed & 1) === 1 ? 'visible' : 'hidden' }" aria-hidden="true">
        &check;
      </span>
      {{ $t('table.agenciesGenitive') }}
    </b-dropdown-item>
    <b-dropdown-item href="#" @click="openOrCloseDay(dayBroadcastingSettings, 2)">
      <span class="text-danger" :style="{ visibility: (dayBroadcastingSettings.is_closed & 2) === 2 ? 'visible' : 'hidden' }" aria-hidden="true">
        &check;
      </span>
      {{ $t('table.saleHousesGenitive') }}
    </b-dropdown-item>

    <b-dropdown-divider></b-dropdown-divider>

    <b-dropdown-text>{{ $t('table.disallowEjection') }}:</b-dropdown-text>
    <b-dropdown-item href="#" @click="disAllowEjection(dayBroadcastingSettings, 3)">
      <span class="text-danger" :style="{ visibility: dayBroadcastingSettings.disallow_ejection === 3 ? 'visible' : 'hidden' }" aria-hidden="true">
        &check;
      </span>
      {{ $t('table.allGenitive') }}
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item href="#" @click="disAllowEjection(dayBroadcastingSettings, 1)">
      <span class="text-danger" :style="{ visibility: (dayBroadcastingSettings.disallow_ejection & 1) === 1 ? 'visible' : 'hidden' }" aria-hidden="true">
        &check;
      </span>
      {{ $t('table.automaticGenitive') }}
    </b-dropdown-item>
    <b-dropdown-item href="#" @click="disAllowEjection(dayBroadcastingSettings, 2)">
      <span class="text-danger" :style="{ visibility: (dayBroadcastingSettings.disallow_ejection & 2) === 2 ? 'visible' : 'hidden' }" aria-hidden="true">
        &check;
      </span>
      {{ $t('table.lowPriorityGenitive') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { BDropdown, BDropdownItem, BDropdownText, BDropdownDivider } from 'bootstrap-vue';
import placementGrid from '@/mixins/placementGrid';
import errorsHandler from '@/utils/errorsHandler';

export default {
  name: 'DayDropdown',
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownText,
    BDropdownDivider,
  },
  mixins: [placementGrid],
  props: {
    colDate: {
      type: String,
      required: true,
    },
    dayBroadcastingSettings: {
      type: Object,
      required: true,
    },
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),
    canCloseDay() {
      return this.$checkPermissions('broadcasting.channel_close');
    },
    canOpenDay() {
      return this.$checkPermissions('broadcasting.channel_open');
    },
  },
  methods: {
    async disAllowEjection(data, param) {
      if (!data?.date) return;
      // param types, decimal (binary):
      // 0 (00) - allow ejection for all
      // 1 (01) - disallow ejection for automatic
      // 2 (10) - disallow ejection for low priority
      // 3 (11) - disallow ejection for all.
      const type = data.disallow_ejection === param || data.disallow_ejection === 3 ? 'allow' : 'disallow';
      await this.$store.dispatch(type === 'allow' ? 'PATCH_BROADCASTING_ALLOW_EJECTION' : 'PATCH_BROADCASTING_DISALLOW_EJECTION', {
        channel_id: this.channel.id,
        days: [
          {
            date: data.date,
            disallow_ejection: param,
          },
        ],
        handler: () => {
          this.$notify({
            type: 'success',
            title: type === 'allow' ? this.$i18n.t('alert.broadcastingAllowEjection') : this.$i18n.t('alert.broadcastingDisallowEjection'),
            text: data.date,
          });
          this.$emit('success');
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },
    async openOrCloseDay(data, param) {
      if (!data?.date) return;
      // param types, decimal (binary):
      // 0 (00) - open to all;
      // 1 (01) - closed for agencies;
      // 2 (10) - closed for sale houses;
      // 3 (11) - closed for all.
      const type = data.is_closed === param || data.is_closed === 3 ? 'open' : 'close';
      await this.$store.dispatch(type === 'open' ? 'PATCH_OPEN_DAYS' : 'PATCH_CLOSE_DAYS', {
        channel_id: this.channel.id,
        days: [
          {
            date: data.date,
            is_closed: param,
          },
        ],
        handler: () => {
          this.$notify({
            type: 'success',
            title: type === 'open' ? this.$i18n.t('alert.openDay') : this.$i18n.t('alert.closeDay'),
            text: data.date,
          });
          this.$emit('success');
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },
  },
};
</script>

<style></style>
