/**
 * Throttle utility helper. Calls a function at intervals of a specified time
 * @param {Function} fn Given function
 * @param {Number} [wait=150] Delay after which the given function will be called
 * @returns
 */
export default function throttle(fn, wait = 100) {
  let throttled = false;
  return function (...args) {
    if (!throttled) {
      fn.apply(this, args);
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, wait);
    }
  };
}
